<template>
  <transition name="tw-modal">
    <div
      v-if="show"
      class="tw-modal-mask"
      :class="zIndex"
    >
      <div class="tw-modal-wrapper">
        <div data-test="backdrop" />
        <div
          ref="target"
          data-test="modal"
          class="tw-modal-container"
          :style="size"
        >
          <div
            v-if="title || $slots.header"
            class="tw-modal-header"
            :class="headerClass"
          >
            <h5
              v-if="title"
              class="text-[1.125rem] font-semibold"
              data-test="title"
            >
              {{ title }}
            </h5>
            <slot name="header" />
          </div>

          <div class="tw-modal-body" :class="bodyClass">
            <slot name="body" />
          </div>

          <div v-if="!hideFooter && ($slots.footer || hasCancel || hasApply)" class="tw-modal-footer">
            <div v-if="!$slots.footer" class="flex justify-end gap-16">
              <WebButton
                v-if="hasCancel"
                variant="outline"
                size="md"
                type="button"
                :loading="cancel.loading"
                :disabled="cancel.disabled"
                @click="$emit('onCancel')"
              >
                {{ cancel.label }}
              </WebButton>
              <WebButton
                v-if="apply"
                variant="primary"
                size="md"
                :type="apply.type"
                :loading="apply.loading"
                :spinner-text="apply.spinnerText"
                :disabled="apply.disabled"
                @click="$emit('onApply')"
              >
                {{ apply.label }}
              </WebButton>
            </div>
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import WebButton from '@shared/components/button/index.vue';

const target = ref(null);

defineEmits(['onApply', 'onCancel']);

const hasCancel = computed(() => {
  return Object.keys(props.cancel).length;
});

const hasApply = computed(() => {
  return Object.keys(props.apply).length;
});

const props = defineProps({
  title: { type: String, default: '' },
  hideFooter: { type: Boolean, default: false },
  cancel: {
    type: Object,
    default: () => {
      return { label: 'Cancel', loading: false, disabled: false };
    }
  },
  apply: {
    type: Object,
    default: () => {
      return { label: 'Apply', loading: false, disabled: false, spinnerText: '', type: 'button' };
    }
  },
  zIndex: { type: String, default: 'z-40' },
  width: { type: Number, default: 440 },
  height: { type: Number, default: null },
  closeOutsideClick: { type: Boolean, default: true },
  disableClickOutside: { type: Boolean, required: false },
  bodyClass: { type: String, default: '' },
  headerClass: { type: String, default: '' }
});

const show = ref(false);

const size = computed(() => {
  const dum = { width: `${props.width}px` };
  return props.height
    ? { ...dum, height: `${props.height}px`, maxHeight: `min(${props.height}px, 100vh - 32px) !important` }
    : dum;
});

onMounted(() => {
  setTimeout(() => {
    show.value = true;
  }, 0);
});
</script>

<style lang="postcss" scoped>
.tw-modal {
  @apply max-h-full;

  &-mask {
    @apply fixed left-0 top-0 h-full w-full bg-[rgba(0,0,0,0.5)] transition-opacity ease-in;
  }
  &-wrapper {
    @apply flex h-full items-center justify-center;
  }
  &-container {
    @apply m-auto flex max-h-full flex-col rounded-8 bg-white transition-all ease-out;
  }
  &-header {
    @apply px-24 pt-24 font-semibold;
  }
  &-body {
    @apply flex-1 overflow-y-auto p-24;
  }
  &-footer {
    @apply border-t border-solid border-t-neutral-200 px-32 py-16;
  }
  &-enter-from,
  &-leave-to {
    @apply opacity-0;
  }
  &-enter-active &-container,
  &-leave-active &-container {
    @apply scale-110;
  }
}
</style>
